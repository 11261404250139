import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
// import  ObliqueRain  from  'react-rainfall-animation/src/ObliqueRain';
import { getContractNft } from "../../libs/smart-contract";
import { _doThis } from "../../libs/utils";
import BearLeft from "../../images/slothLeftfre.png";
import BearRight from "../../images/slothRight (1).png";
import {
  BearImages,
  BearImgDiv,
  BearRightImages,
  Bg,
  BtnCW,
  InputMint,
  MegaDiv,
  MintBtn,
  MintContainer,
  NavBtn,
  Rain,
  WholeDiv,
} from "./HeroElements";
function HeroSection() {
  const [loading, setLoading] = useState(false);
  const [mintCount, setMintCount] = useState(1);
  const [minted, setMinted] = useState(0);
  const _minted = async () => {
    const mint = getContractNft();
    const priceOfPenguins = await mint.methods.totalSupply().call();
    console.log("priceOfPenguins", priceOfPenguins);
    return setMinted(8000 - Number(priceOfPenguins));
  };
  useEffect(() => {
    _minted();
    setInterval(() => {
      _minted();
    }, 3000);
  }, []);

  const Mint = () => (
    <>
      {minted <= 0 ? null : (
        <div className="hero-mint">
          <div className="incr-decr">
            <button
              className="add-button"
              onClick={() => {
                setMintCount(mintCount - 1);
              }}
              disabled={mintCount === 1}
            >
              <span className="button-style">
                <svg
                  className="svg-button"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 13H5v-2h14v2z"></path>
                </svg>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
            <h3 className="mint-count">{mintCount}</h3>
            <button
              className="add-button"
              onClick={() => {
                setMintCount(mintCount + 1);
              }}
              disabled={mintCount === 10}
            >
              <span className="button-style">
                <svg
                  className="svg-button"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                </svg>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
          </div>
          <div className="button-minting">
            <div style={{ textAlign: "center" }}>
              <button
                className="MuiButton-contained"
                onClick={() => {
                  _doThis(async (account, web3) => {
                    if (!mintCount || isNaN(mintCount)) {
                      alert("Enter some nft quantity to buy");
                      return;
                    }

                    const nft = getContractNft(web3);
                    const price = web3.utils.fromWei(
                      await nft.methods.itemPrice().call()
                    );

                    const _howMany = Number(mintCount);
                    const totalPrice = web3.utils.toWei(
                      (Number(price) * _howMany).toString()
                    );
                    const purchase = nft.methods.purchaseTokens(mintCount);
                    let options = {
                      from: account,
                      gas: "0",
                      value: totalPrice,
                    };
                    try {
                      const estimateGas = Math.trunc(
                        await purchase.estimateGas(options)
                      );
                      options = {
                        ...options,
                        gas: "" + estimateGas,
                      };
                    } catch (e) {
                      let msg = e.message.split("\n")[0].split("reverted:")[1];

                      if (!msg) msg = "Insufficient funds";

                      alert(msg);
                      return;
                    }

                    try {
                      setLoading(true);
                      await purchase.send(options).on("confirmation", (i) => {
                        //here
                        if (i === 0) {
                          setLoading(false);
                          if (
                            window.confirm(
                              `Welcome to the One Jungle! Go check out your Buzzard on opensea.io`
                            )
                          ) {
                            window.location.href = `https://opensea.io/${account}`;
                          }
                        }
                      });
                    } catch (e) {
                      setLoading(false);
                      alert(e.message);
                    }
                  }, true);
                }}
              >
                <span className="span-labe-m">
                  {loading ? "Minting..." : "Mint"}
                </span>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
  const Completionist = () => (
    <div className="timer">
      <div className="timer-space">
        <div className="margin-timer">
          <div className="countdown-container">
            <h3 className="mint-header">Presale Ends In </h3>
            <div className="timer-adjustment">
              <span className="bird-nft-home__manifest__countdown-item">
                <p className="text-good">0</p>
                <span className="text-good1">Days</span>
              </span>
              <span className="bird-nft-home__manifest__countdown-item">
                <p className="text-good">0</p>
                <span className="text-good1">Hours</span>
              </span>
              <span className="bird-nft-home__manifest__countdown-item">
                <p className="text-good">0</p>
                <span className="text-good1">Minutes</span>
              </span>
              <span className="bird-nft-home__manifest__countdown-item-last">
                <p className="text-good">0</p>
                <span className="text-good1">Seconds</span>
              </span>
            </div>
          </div>
          <Mint />
        </div>
      </div>
    </div>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (false) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown

      //       <div className="landscape"></div>
      // <div className="glass"></div>
      return (
        <>
          <div className="timer">
            <div className="timer-space">
              <div className="margin-timer">
                <div className="countdown-container">
                  <div style={{ marginTop: "-2rem" }}>
                    <div className="banner-header"></div>
                    <WholeDiv>
                      <BearImgDiv>
                        <BearImages src={BearLeft} alt="bearleft" />
                      </BearImgDiv>

                      <MegaDiv>
                        <div className="banner-header">
                          <h3 className="mint-header">
                            Welcome To One Jungle
                            <br />
                            Free Mint!
                          </h3>
                          <h3 className="mint-header3">
                            You can mint only One NFT!
                          </h3>
                        </div>

                        <NavBtn>
                          <BtnCW>Sloth Collection</BtnCW>
                        </NavBtn>
                        <MintContainer>
                          <NavBtn>
                            <InputMint
                              type="number"
                              min="1"
                              max="1"
                              value={mintCount}
                              onChange={(e) => setMintCount(e.target.value)}
                            />
                          </NavBtn>
                        </MintContainer>
                        <NavBtn>
                          <MintBtn
                            onClick={() => {
                              console.log("checkingValue :" + loading);
                              _doThis(async (account, web3) => {
                                if (!mintCount || isNaN(mintCount)) {
                                  alert("Enter some nft quantity to buy.");
                                  return;
                                }

                                const nft = getContractNft(web3);

                                const price = web3.utils.fromWei(
                                  await nft.methods.itemPrice().call()
                                );

                                const _howMany = Number(mintCount);
                                const totalPrice = web3.utils.toWei(
                                  (Number(price) * _howMany).toString()
                                );

                                const purchase =
                                  nft.methods.purchaseTokens(mintCount);
                                let options = {
                                  from: account,
                                  gas: "0",
                                  value: totalPrice,
                                };
                                try {
                                  const estimateGas = Math.trunc(
                                    await purchase.estimateGas(options)
                                  );
                                  options = {
                                    ...options,
                                    gas: "" + estimateGas,
                                  };
                                } catch (e) {
                                  let msg = null;

                                  try {
                                    let a = e.message;
                                    msg = JSON.parse(
                                      a.substring(
                                        a.indexOf("{"),
                                        a.lastIndexOf("}") + 1
                                      )
                                    ).message;
                                    msg = msg.replace("err: ", "");
                                    msg = msg.replace(
                                      "execution reverted: ",
                                      ""
                                    );
                                  } catch (eiii) {}

                                  if (!msg || msg === undefined) {
                                    msg =
                                      "Insufficient funds or some data error";
                                  }

                                  alert(msg);
                                  return;
                                }

                                try {
                                  setLoading(true);
                                  await purchase
                                    .send(options)
                                    .on("confirmation", (i) => {
                                      //here
                                      if (i === 0) {
                                        setLoading(false);
                                        if (
                                          window.confirm(
                                            `Welcome to the One Jungle Organization ! Go check out your Sloth NFTs on opensea.io`
                                          )
                                        ) {
                                          window.location.href = `https://opensea.io/${account}`;
                                        }
                                      }
                                    });
                                } catch (e) {
                                  setLoading(false);
                                  alert(e.message);
                                }
                              }, true);
                            }}
                          >
                            Mint
                          </MintBtn>
                        </NavBtn>
                      </MegaDiv>
                      <BearImgDiv>
                        <BearRightImages src={BearRight} alt="bearleft" />
                      </BearImgDiv>
                    </WholeDiv>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  // <div className = "lightining">
  // </div>

  // <div className="lightining">
  // </div>
  // </div>
  // <  className="rain">
  // <div className="rain">
  // <ObliqueRain  dropletsAmount={7000}  amplitude={100}  />
  // </div>
  //
  return (
    <>
      <Bg>
        <Rain>
          <Countdown
            date={Date.parse("24 Mar 2021 9:00:00 UTC")}
            renderer={renderer}
          />
        </Rain>
      </Bg>
    </>
  );
}
export default HeroSection;
